<template>
  <a-modal title="发放明细" width="50%" :visible="visible" :destroyOnClose="true" :footer="null" @cancel="handleCancel">
    <div class="">
      <div class="right-content">
        <!-- 筛选 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="40">
              <a-col :md="8" :sm="24">
                <a-form-item label="卡号">
                  <a-input v-model="queryParam.cardNo" allow-clear placeholder="请输入" />
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-button type="primary" @click="initTableData">搜索</a-button>
                <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false">
          <span slot="customerId" slot-scope="text, record">
            <a-tag :color="record.customerId ? 'red' : 'green'">{{ record.customerId ? '是' : '否' }} </a-tag>
          </span>
        </a-table>
      </div>

      <!-- 分页组件 -->
      <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
        <a-pagination
          :pageSize="page.pageSize"
          :current="page.pageNo"
          show-size-changer
          :page-size-options="['10', '20', '50', '100']"
          show-quick-jumper
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="pageChange"
          @showSizeChange="sizeChange"
        >
        </a-pagination>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { exchangeIntegralInvoicePage } from '@/api/modular/mallLiving/recharge'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },

  {
    title: '卡号',
    align: 'center',
    width: '150px',
    dataIndex: 'cardNo',
  },
  {
    title: '密码',
    align: 'center',
    width: '150px',
    dataIndex: 'pwd',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '100px',
    align: 'center',
  },
  {
    title: '激活码',
    align: 'center',
    width: '150px',
    dataIndex: 'liveNo',
  },
  {
    title: '是否兑换',
    width: '100px',
    align: 'center',
    scopedSlots: { customRender: 'customerId' },
  },
  {
    title: '兑换人',
    dataIndex: 'customerName',
    width: '100px',
    align: 'center',
  },
  {
    title: '兑换时间',
    dataIndex: 'exchangeTime',
    width: '100px',
    align: 'center',
  },
]
export default {
  name: 'redeemCardDetail',
  data() {
    return {
      columns,
      visible: false, //modal框显示状态
      data: [],
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      exchangeSendRecordId: '',
    }
  },

  methods: {
    init(record) {
      this.visible = true
      this.exchangeSendRecordId = record.id
      this.initTableData()
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: { ...this.queryParam, exchangeSendRecordId: this.exchangeSendRecordId },
        page: this.page,
      }
      exchangeIntegralInvoicePage(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style scoped>
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
